import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { isMobile } from 'react-device-detect';

const Web3 = require("web3");

const CONFIG = {
	"CONTRACT_ADDRESS": "",
	"NETWORK": {
		"NAME": "Ethereum",
		"SYMBOL": "ETH",
		"ID": 1
	},
	"SHOW_BACKGROUND": true
}

const webAddress = '/'

export default class App extends Component {

	state = {
		errorMessage: '',
		connected: false,
		sending: false,
		pandaCount: 1,
	}

	async ethEnabled() {
		// if (window.web3) {
		//   window.web3 = new Web3(window.web3.currentProvider);
		//   window.ethereum.enable();
		//   return true;
		// }
		// return false;

		if (window.ethereum) {
			await window.ethereum.send('eth_requestAccounts');
			window.web3 = new Web3(window.ethereum);
			return true;
		}
		return false;

	}

	render() {

		return <div class="">
			<div class="">
				<div class="">
					<div className=''>

					</div>
					<div class="">
						<div class="">
								<div>
							
								</div>
						</div>
						<div class="">										
							<div className=''>
							</div>
							<div className=''>		
							</div>

							<div className=''></div>							
															
						</div>
						
						</div>
					
					</div>
				</div>
			</div>
	}
}

